<template>
  <div>
    <div id="pc1">
      <div style="text-align: center" class="hand">
        <img src="@/assets/images/homeTop.png" alt="" style="width: 7.5rem" />
      </div>
      <div style="margin: 0.3rem 0"></div>
      <div style="margin: 0 auto; width: 60%; display: flex">
        <div>
          <img src="../../assets/官网切图/022017230607_0Asset 1@2x 1.png" alt="" style="width: 100%;margin-top: 0.3rem;" />
        </div>
        <div style="width: 600px;margin-left: 0.5rem;margin-top: 0.4rem;">
          <div>
            <div style="text-align: center">
              <img src="../../assets/官网切图/022017241912_0椭圆 2 拷贝 3.png" alt="" style="width: 2rem;" />
            </div>
            <div class="message" style="color: #d3bc93" v-html="message[0].content" @click="companyProfile"></div>
          </div>
        </div>
      </div>
      <div class="contain3">
        <div style="text-align: center">
          <img src="../../assets/官网切图/022017250518_0椭圆 2 拷贝 2.png" alt="" style="width: 2rem" />
        </div>
        <div class="newmessage" style="width: 53%;margin: 27px auto 0px;">
          <div style="display: flex" class="messagenow">
            <div v-for="(item, index) in news" :key="index" class="messagecontain">
              <div class="contunt1">
                <div style="padding: 0.1rem">
                  <div>
                    <img :src="item.showImage" alt="" style="width: 270px; height: 170px;cursor: pointer;"
                      @click="gonewsdetail(item)" />
                    <div style="
                                                        color: #d3bc93;
                                                        font-size: 20px;
                                                        font-weight: 700;
                                                        margin-top: 20px;
                                                        height: 60px;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        display: -webkit-box;
                                                        -webkit-line-clamp: 2;
                                                        cursor: pointer;
                                                        -webkit-box-orient: vertical" @click="gonewsdetail(item)">
                      {{ item.title }}
                    </div>
                    <div style="
                                                        color: #d3bc93;
                                                        font-size: 14px;
                                                        height: 45px;
                                                        margin-top: 20px;
                                                        cursor: pointer;
                                                      " class="messagedetail" @click="gonewsdetail(item)">
                      {{ item.highlightContent }}
                    </div>
                    <div style="
                                                        text-align: center;
                                                        line-height: 35px;
                                                        border: 1px solid #d3bc93;
                                                        height: 35px;
                                                        border-radius: 50px 50px 50px 50px;
                                                        font-size: 14px;
                                                        width: 35%;
                                                        margin-top: 0.3rem;
                                                        overflow: auto;
                                                        cursor: pointer;
                                                      " @click="gonewsdetail(item)">
                      <a style="color: #d3bc93"> 了解详情 </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div style="
                                                margin: 0.2rem auto;
                    text-align: center;
                    line-height: .26667rem;
                    height: 0.26667rem;
                    border-radius: 0.66667rem 0.66667rem 0.66667rem 0.66667rem;
                    width: 0.8rem;
                    font-size: .10667rem;
                    color: #d3bc93;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 0.01333rem solid #d3bc93;
                    -webkit-transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,box-shadow .5s;
                    transition: border .5s,box-shadow .5s,-webkit-box-shadow .5s;
                                              " @click="gonewcenter">
              <a style="color: #d3bc93"> 更多新闻 </a>
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 0.3rem 0"></div>
      <div class="contain4">
        <div style="text-align: center">
          <img src="../../assets/官网切图/022017262174_0椭圆 2 拷贝.png" alt="" style="width: 2rem" />
        </div>
        <div class="product">
          <div v-for="(item, index) in products" :key="index" class="productson" @click="goprodetail(item)">
            <a>
              <div style="margin: 0.1rem;">
                <div style="text-align: center; font-size: 20px; color: #d3bc93">
                  {{ item.title }}
                </div>
                <div style="font-size: 14px; color: #d3bc93" class="messagedetail" v-html="item.content"></div>
                <div style="text-align: center;padding: 0.2rem;">
                  <img :src="item.showImage" alt="" style="width: 1rem" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <div style="
                                                margin: 0.2rem auto;
                    text-align: center;
                    line-height: .26667rem;
                    height: 0.26667rem;
                    border-radius: 0.66667rem 0.66667rem 0.66667rem 0.66667rem;
                    width: 0.8rem;
                    font-size: .10667rem;
                    color: #d3bc93;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 0.01333rem solid #d3bc93;
                    -webkit-transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,box-shadow .5s;
                    transition: border .5s,box-shadow .5s,-webkit-box-shadow .5s;
                                              " @click="goProducts">
              <a style="color: #d3bc93"> 更多产品 </a>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 50px;"></div>
      <div class="contain5">
        <div style="text-align: center">
          <img src="../../assets/官网切图/022017271262_0服务案例.png" alt="" style="width: 2rem" />
        </div>
        <div style="width: 50%;margin: 0.26667rem auto;">
          <el-carousel :interval="4000" type="card" height="350px">
            <el-carousel-item v-for="(item, index) in sercase" :key="index">
              <img :src="item.showImage" style="width: 100%;height: 85%;" alt="" @click="goserdetail(item)" />
              <h3 style="font-size: 0.16rem;
        margin: 0px;
        text-align: center;
        color: rgb(255, 255, 255);" @click="goserdetail(item)">{{ item.title }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div>
          <div>
            <div style="
                                                margin: 0.2rem auto;
                    text-align: center;
                    line-height: .26667rem;
                    height: 0.26667rem;
                    border-radius: 0.66667rem 0.66667rem 0.66667rem 0.66667rem;
                    width: 0.8rem;
                    font-size: .10667rem;
                    color: #d3bc93;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    border: 0.01333rem solid #d3bc93;
                    -webkit-transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,-webkit-box-shadow .5s;
                    transition: border .5s,box-shadow .5s;
                    transition: border .5s,box-shadow .5s,-webkit-box-shadow .5s;
                                              " @click="goServerCase()">
              <a style="color: #d3bc93"> 更多案例 </a>
            </div>
          </div>
        </div>
        <!-- <div class="service">
          <div v-for="(item, index) in sercase" :key="index" class="serviceson" @click="goserdetail(item)">
            <div>
              <a>
                <img :src="item.showImage" alt="" />
              </a>
            </div>
          </div>
        </div> -->
      </div>
      <div style="width: 100%; height: 50px"></div>
    </div>
    <div id="mobiel1">
      <div class="contant1">
        <img src="../../assets/APP 首页_slices/022017294198_03.png" alt="" style="width: 80%" />
      </div>
      <div class="yd-contain2">
        <div>
          <div style="text-align: center">
            <img src="../../assets/APP 首页_slices/022017322590_0椭圆 2 拷贝 3.png" alt="" />
          </div>
          <div style="margin: 0.3rem 0">
            <div class="yd-message" v-html="message[0].content"></div>
          </div>
        </div>
      </div>
      <div class="yd-contain3">
        <div style="text-align: center">
          <img src="../../assets/APP 首页_slices/022017331640_0椭圆 2 拷贝 2.png" alt="" />
        </div>
        <div class="yd-newmessage">
          <div style="padding: 20px">
            <div style="text-align: center">
              <img src="../../assets/官网切图/图层 22.png" alt="" />
            </div>
            <div class="message-year">{{ news[0].created }}</div>
            <div style="color: #d3bc93; height: 50px" v-html="news[0].content" class="messagedetail"></div>
            <div class="more" @click="gonewsdetail(news[0])">
              <a style="color: #d3bc93">了解更多</a>
            </div>
          </div>
        </div>
      </div>
      <div class="yd-contain4">
        <div style="text-align: center">
          <img src="../../assets/APP 首页_slices/椭圆 2 拷贝.png" alt="" />
        </div>
        <div class="yd-product">
          <div style="padding: 0.1rem">
            <div style="text-align: center; color: #d3bc93; font-size: 20px">
              <span>{{ products[0].title }}</span>
            </div>
            <div style="color: #d3bc93" class="messagedetail" v-html="products[0].content"></div>
            <div style="text-align: center">
              <img src="../../assets/APP 首页_slices/图层 9.png" alt="" />
            </div>
          </div>
        </div>
        <div class="knowmore" @click="goprodetail(products[0])">
          <a style="color: #d3bc93">了解更多</a>
        </div>
      </div>
      <div class="yd-contain5">
        <div class="servercase">
          <div style="text-align: center; margin-bottom: 0.1rem">
            <img src="../../assets/APP 首页_slices/椭圆 2 拷贝 4.png" alt="" />
          </div>
          <div style="
                                              border: 1px soild #d3bc93;
                                              text-align: center;
                                              margin: 0.4rem 0;
                                            ">
            <div>
              <img :src="sercase[0].showImage" alt="" />
            </div>
          </div>
          <div class="knowmore" @click="goserdetail(sercase[0])">
            <a style="color: #d3bc93">了解更多</a>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 50px"></div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";
import img0 from "../../assets/官网切图/图层.png";
import img1 from "../../assets/官网切图/图层 25.png";
import img2 from "../../assets/官网切图/图层 17 (1).png";
import img3 from "../../assets/官网切图/图层 25.png";
import img4 from "../../assets/官网切图/图层 19 (1).png";
import img5 from "../../assets/官网切图/图层 25.png";
export default {
  data() {
    return {
      message: [], //简介
      news: [], //新闻
      products: [], //产品
      sercase: [], //服务案例
      imgs: [
        { url: img0 },
        { url: img1 },
        { url: img2 },
        { url: img3 },
        { url: img4 },
        { url: img5 },
      ],
      userInfo: {
        setting: [],
      },
      bindData: "",
      bannerList: [
        {
          url: require("../../assets/website/shou1.jpg"),
          // url:"../../assets/website/shou1.jpg"
        },
        {
          url: require("../../assets/website/shou2.jpg"),
          // url:"../../assets/website/shou2.jpg"
        },
      ],
    };
  },
  // created() {},
  activated() { },
  methods: {
    gonewcenter() {
      this.$router.push({
        path: "/newcenter",
      });
    },
    async getlist() {
      // debugger 打断点
      await postAction(
        "/api/article/listByCategorySlug",
        {
          categorySlug: "desc",
          count: 1,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        this.message = res.list;
      });
      await postAction(
        "/api/article/listByCategorySlug",
        {
          categorySlug: "news",
          count: 3,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        this.news = res.list;
      });
      await postAction(
        "/api/article/listByCategorySlug",
        {
          categorySlug: "product",
          count: 6,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        this.products = res.list;
      });
      await postAction(
        "/api/article/listByCategorySlug",
        {
          categorySlug: "case",
          count: 6,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        this.sercase = res.list;
      });

    },
    goProducts() {
      this.$router.push('/products');
    },
    gonewsdetail(val) {
      this.$router.push({
        path: "/newcenterdetail",
        query: {
          id: val.id,
          slug: val.siteId,
        },
      });
    },
    companyProfile() {
      this.$router.push('/bussintroduction')
    },
    goprodetail(val) {
      this.$router.push({
        path: "/products/detail",
        query: {
          id: val.id,
          slug: val.siteId,
        },
      });
    },
    goServerCase() {
      this.$router.push({
        path: "/case",
      });
    },
    goserdetail(val) {
      this.$router.push({
        path: "/casedetail",
        query: {
          id: val.id,
          slug: val.siteId,
        },
      });
    },
  },
  mounted(){
    debugger

    this.getlist();

  },
  created() {

    // 判断pc和移动
    // let str = window.navigator.userAgent;
    // if (str.toLowerCase().indexOf("mobile") == -1) {
    //   console.log(document.getElementById("pc"), "pc777777777777");
    //   document.getElementById("pc1").style.display = "block";
    //   document.getElementById("mobiel1").style.display = "none";
    // } else {
    //   document.getElementById("mobiel1").style.display = "block";
    //   document.getElementById("pc1").style.display = "none";
    // }
  },
  destroyed() { },
};
</script>
<style lang="less" scoped>
#pc1 {
  display: none;
  width: 100%;
  height:100%;
}

#mobiel1 {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc1 {
    display: none;
  }

  #mobiel1 {
    display: block;
    background-color: #111421;
  }
}

@media screen and (min-width: 768px) {
  body {
    //  background-color: #111421 !important;
  }

  #pc1 {
    display: block;
    background-image: url(../../assets/官网切图/官网-背景.png);
  }

  #mobiel1 {
    display: none;
  }
}

.hand {
  background-image: url(../../assets/官网切图/首图背景.png);
}

.messagedetail {
  display: -webkit-box !important;
  overflow: hidden;

  word-break: break-all;
  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// .messagenow .messagecontain:nth-child(2) {
//   margin: 0 -1rem;
// }
.messagecontain {
  width: 32%;
  margin: 10px;
}

#someID .text:nth-child(2) {
  background: red;
}

.yd-contain5 {
  margin: 0.4rem 0;
}

.servercase {
  margin: 0.1rem 0.5rem;
}

.knowmore {
  margin: 0.1rem auto;
  text-align: center;
  line-height: 31px;
  border: 1px solid #d3bc93;
  height: 31px;
  border-radius: 50px 50px 50px 50px;
  font-size: 14px;
  width: 25%;
  color: #d3bc93;
}

.yd-product {
  margin: 0.4rem 0.5rem;
  border: 1px solid #d3bc93;
}

.yd-newmessage {
  margin: 0.4rem 0.4rem;
  background-color: #252525;

  .message-year {
    color: #d3bc93;
    font-size: 24px;
  }

  .message-contain {
    color: #d3bc93;
    font-size: 14px;
  }

  .more {
    margin: 0.1rem auto;
    text-align: center;
    line-height: 31px;
    border: 1px solid #d3bc93;
    height: 31px;
    border-radius: 50px 50px 50px 50px;
    font-size: 14px;
    width: 35%;
    color: #d3bc93;
  }
}

.yd-message {
  margin: 0.1rem 0.5rem;
  color: #d3bc93;
}

img {
  max-height: 100%;
  max-width: 100%;
}

/deep/.el-carousel__button {
  width: 0.04rem;
  height: 0.04rem;
  border: none;
  border-radius: 50%;
  background: rgb(255, 255, 255);
}

/deep/.el-carousel__indicator.is-active button {
  background-color: rgb(211, 188, 147);
}

/deep/.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  background-color: rgb(211, 188, 147);
  opacity: 1;
}

/deep/.el-carousel__item:nth-child(2n),
/deep/.el-carousel__item:nth-child(odd) {
  background-color: #111421;
}

.contant1 {
  width: 100%;
  text-align: center;
  padding: 0.3rem;
}

.contain5 {
  margin-bottom: 0.5rem;
}

.serviceson {
  // padding: 0.1rem;
  width: 27.5%;
}

.service {
  width: 62%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.3rem auto;
}

.productson {
  // width: 25%;
  // // height: auto;
  // border: 1px dashed #d3bc93;
  // margin: 0.1rem;
  width: 25%;
  margin: 0.1rem;
  background: #111421;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.01333rem solid #d3bc93;
  overflow: hidden;
}

.productson img:hover {
  transform: scale(1.07);

}

.productson img {
  transition: all .3s ease .1s;
}

.product {
  display: flex;
  justify-content: center;
  margin: 0.23rem auto;
  flex-wrap: wrap;
  width: 62%;
  //margin-top: 0.1rem;
}

.message {
  // margin-top: 0.1rem;
  // width: 100%;
  // color: aqua;
  color: rgb(211, 188, 147);
  cursor: pointer;
  margin: 10px auto;
  width: 85%;
  // margin-left: 50px;
}

.contunt1 {
  background-color: #242424;
  margin: 0 auto;
  // height: 100%;
}

.contunt1:hover {
  transform: scale(1.03);
  transition: all .3s linear;
}
</style>

<style>
::-webkit-scrollbar {
  display: none;
}
</style>